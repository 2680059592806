// import * as firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_apiKey,
    authDomain: process.env.VUE_APP_FIREBASE_authDomain,
    // databaseURL: process.env.VUE_APP_FIREBASE_databaseURL,
    projectId: process.env.VUE_APP_FIREBASE_projectId,
    storageBucket: process.env.VUE_APP_FIREBASE_storageBucket,
    messagingSenderId: process.env.VUE_APP_FIREBASE_messagingSenderId,
    appId: process.env.VUE_APP_FIREBASE_appId,
    measurementId: process.env.VUE_APP_FIREBASE_measurementId,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.analytics().logEvent('init');
export const db = firebaseApp.firestore();