<template>
  <h3>Follow us</h3>
  <ul>
    <!-- <li><a href="mailto:info@itsonsale.nl">info@itsonsale.nl</a></li> -->
    <li>
      <a
        rel="noopener noreferrer"
        href="https://www.instagram.com/itsonsale.now/"
        target="_blank"
        ><img alt="instagam logo" src="../assets/socials/instagram.png"
      /></a>
    </li>
  </ul>
</template>

<script>
// import NewsLetterForm from "./NewsletterForm.vue"

export default {
  name: "Socials",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  padding: 8px;
  /* display: inline-block; */
  margin: 0 10px;
}
a {
  /* color: var(--tertiary-color); */
  color: var(--tertiary-color);
}
img {
  width: 36px;
}
</style>
