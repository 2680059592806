<template>
  <div class="hello">
    <div class="row">
      <div class="double-column">
        <div class="item">
          <h1>{{ msg }}</h1>
          <p>
            Do you recognize that feeling? Falling totally in love with fashion
            but your bank account says no? Uhuh! At <strong>itsonsale</strong> we will
            provide you with the top salespicks of the nicest brands.
          </p>
          <p>
            Like your own style? We are working on some features where you can
            save your own items and we will alert you when
            the item is on sale. <strong>Be the first</strong> to get updates on
            our releases by signing up below.
          </p>
        </div>
      </div>
      <div class="column order">
        <div class="item">
          <img src="../assets/posts/2.png" alt="itsonsale image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NewsLetterForm from "./NewsletterForm.vue"

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin: 0;
}
img {
  opacity: 0.6;
  border-radius: 10px;
  max-width: 200px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  padding: 8px;
  /* display: inline-block; */
  margin: 0 10px;
}
a {
  color: #49c1a7;
}
@media only screen and (min-width: 768px) {
  /* bigger than mobile: */
  .order {
    order: -1;
  }
}
</style>
