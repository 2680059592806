<template class="app">
  <div class="wrapper">
    <div class="row">
      <div class="column">
        <div class="item padding">
          <div class="logo">
            <img alt="instagam logo" src="./assets/logo.png" />
          </div>
          <HelloWorld msg="Hello 👋" />
        </div>
      </div>
    </div>
    <div class="row 2">
      <div class="double-column">
        <div class="item">
          <NewsletterSignUp />
        </div>
      </div>
      <div class="column">
        <div
          class="item padding"
          style="background-color: var(--tertiary-color)"
        >
          <Socials />
        </div>
      </div>
    </div>
    <!-- <div class="row 3">
      <div v-for="post in posts" :key="post.id" class="column">
        <div class="item padding post" :class="post.classItem">
          <div class="post-content">
            <Posts :blog="post.blog" :img="post.img" />
          </div>
          <img
            :src="require('./assets/posts/' + post.img.url)"
            :alt="post.img.alt"
          />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import NewsletterSignUp from "./components/NewsletterSignUp.vue";
import Socials from "./components/Socials.vue";
// import Posts from "./components/Posts.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
    NewsletterSignUp,
    Socials,
    // Posts,
  },
  data() {
    return {
      posts: [
        {
          id: 1,
          // classItem: 'color-4',
          img: {
            url: "1-unsplash.jpg",
            alt: "check our style - itsonsale",
          },
          blog: {
            title: "Check our style",
            body:
              "Living a <strong>busy life</strong> means that you don’t always have time to shop. Lucky for you, we love to shop! Not only items, but full outfits that are waiting for you to be ordered. Don’t need it yet? You can <strong>save the items</strong> we show you in your own <strong>virtual closet</strong> and buy later. And if you just wait for it, we will notify you when your favourite outfit is <strong>on sale</strong>! Saves you time and money! The only thing you need to do is leave your e-mail address so we can reach you!",
          },
        },
        {
          id: 2,
          // classItem: 'color-3',
          img: {
            url: "2-unsplash.jpg",
            alt: "love save buy - itsonsale",
          },
          blog: {
            title: "Love.. save.. Buy",
            body:
              "Love it! Nah… too expensive. In love with an item that is just a <strong>bit too expensive</strong>? It can be within reach if you just wait for it. Store your item in your <strong>own virtual closet</strong> at Itsonsale and we will notify you when that beauty is on sale! The wait makes the reward of opening that package even better! Don’t miss out on fashion love and register now by leaving your email dress or following us on Instagram!",
          },
        },
        {
          id: 3,
          // classItem: 'color-2',
          img: {
            url: "3-unsplash.jpg",
            alt: "dress a friend - itsonsale",
          },
          blog: {
            title: "Dress a Friend",
            body:
              "Love an item <strong>for a friend</strong>? Hang it in their virtual closet! Share items, fashion tips and dress your friends. Another thing you can do is open up your closet for your friends so they can pick the perfect gift for you!",
          },
        },
        {
          id: 4,
          // classItem: 'color-4',
          img: {
            url: "4-unsplash.jpg",
            alt: "inside whoes closet? - itsonsale",
          },
          blog: {
            title: "Inside whose closet?",
            body:
              "Sharing is caring. Open up your closet so others can <strong>get inspired</strong> by your style! Show your style, find people that love it and become one of our top stylists! Need some inspiration? Check the closets of others to see which items they love. It might also be your style.",
          },
        },
        {
          id: 5,
          // classItem: 'color-1',
          img: {
            url: "unsplash.jpg",
            alt: "dressed by itsonsale",
          },
          blog: {
            title: "Dressed by Itsonsale",
            body:
              "<strong>Same shirt, different day</strong>. Lack inspiration to look fabulous? Send us five pictures of styles you like, and we will dress you up!",
          },
        },
      ],
    };
  },
};
</script>

<style>
@import "./assets/styles/variables.css";

/* Mobile first layout */
body {
  background: var(--primary-color);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding: 0px 10px 0px 10px;
}
.logo {
  border-radius: 10px;
  background-color: var(--quaternary-color);
  margin-bottom: 30px;
  margin: 0px -10px 10px -10px;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.item {
  min-width: 150px;
  height: 100%;
  margin: 10px;
  background-color: var(--secondary-color);
  border-radius: 10px;
}
.post {
  position: relative;
  overflow: hidden;
  min-height: 250px;
}
.post img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  opacity: 0.2;
}
.post .post-content {
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  .post:hover img {
    opacity: 0.6;
  }
  .post:hover .post-content {
    opacity: 0;
  }
}
.wrap {
  flex-wrap: wrap;
}
.text {
  /* padding: 0px 5px 0px 5px; */
  padding: 0px 10px 0px 10px;
}
.padding {
  padding: 0px 10px 0px 10px;
}
h3 {
  margin: 40px 0 0 0;
}
@media only screen and (min-width: 768px) {
  /* For Tables: */
  #app {
    padding: 0;
  }
  .post {
    min-width: 300px;
  }
  .column {
    flex: 1;
  }
  .double-column {
    flex: 2;
  }
  .padding {
    padding: 0px 40px 0px 40px;
  }
  .logo {
    margin: 0px -40px 40px -40px;
  }
}
@media only screen and (min-width: 1024px) {
  /* For Desktop: */
  #app {
    padding: 0px 40px 0px 40px;
  }
  .padding {
    padding: 0px 40px 30px 40px;
  }
  .logo {
    margin: 0px -40px 30px -40px;
  }
}
</style>
