<template>
  <h3>🤩 Be the first to get notified 🤩</h3>
  <div class="signup-form">
    <!-- START FORM -->

    <form v-if="!isSignedUp" class="form" @submit.prevent="onFormSubmit">
      <div class="form-group fg3">
        <input
          type="email"
          class="form-control"
          placeholder="email"
          v-model="user.email"
          required
        />
      </div>

      <div class="form-group">
        <button :disabled="disabled">send</button>
      </div>
    </form>

    <div class="thankyou-note" v-if="isSignedUp">
      yeay 🎉 you'll be the first to know
    </div>

    <!-- END FORM -->
  </div>
</template>

<script>
// import NewsLetterForm from "./NewsletterForm.vue"
import { db } from "../firebaseDb";

export default {
  name: "NewsletterSignUp",
  props: {
    msg: String,
  },
  data() {
    return {
      user: {
        email: "",
        updatedAt: new Date()
      },
      isSignedUp: false,
      disabled: false,
    };
  },
  methods: {
    onFormSubmit() {
      event.preventDefault();
      this.disabled = true;
      db.collection("newsletter")
        .add(this.user)
        .then(() => {
          this.isSignedUp = true;
          this.disabled = false;
          this.user.email = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form {
  display: flex;
}
.form-group {
  padding: 8px;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.fg3 {
  flex-grow: 3;
}
.thankyou-note {
  padding: 16px 32px;
  color: #49C1A7;
}
button {
  width: 100%;
  /* background-color: #49C1A7; */
  background-color: var(--tertiary-color);
  border-radius: 10px;
  border: none;
  /* color: white; */
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
}
input {
  padding: 14px 16px;
  text-decoration: none;
  margin: 4px 2px;
  box-sizing: border-box;
  /* border: 2px solid #49C1A7; */
  border: 2px solid var(--tertiary-color);
  background-color: inherit;
  border-radius: 10px;
  width: 100%;
}
</style>
